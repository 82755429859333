import Header from '../components/Header'
import Footer from '../components/Footer'


const layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <Header />
        <main className='relative'> {children}</main>
      <Footer />
    </>
  )
}

export default layout
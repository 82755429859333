import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Routes/Home';
import Layout from "./layouts/Layout"
import { useEffect } from 'react';

const App = () => {

  useEffect(() => {
    document.title = "Strange Solutions"
  }, [])


  return (
    <BrowserRouter>
    <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
    </Layout>
    </BrowserRouter>
  )
}

export default App

import React from 'react'

const Home = () => {
  return (
      <div className='container mx-auto h-screen'>
        <section className='flex justify-center  items-center  h-full'>
          <h1 className='text-4xl text-center'>AI is not your friend</h1>
        </section>
      </div>
  )
}

export default Home
import logo from "../assets/strange-logo.png"

const Footer = () => {
  return (
    <footer className="bg-white shadow dark:bg-gray-900 ">
        <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
            <div className="sm:flex sm:items-center sm:justify-between">
                <a href="https://strangesolutions.ai/" className="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse">
                    <img src={logo} className="h-8" alt="Strange Solutions Logo" />
                </a>
                <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
                    <li>
                        <a href="mailto:strangesolutions.ai@gmail.com" className="hover:underline">Contact</a>
                    </li>
                </ul>
            </div>
            <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
            <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2024 <a href="https://strangesolutions.ai/" className="hover:underline">Strange Solutions™</a>. All Rights Reserved.</span>
        </div>
    </footer>
  )
}

export default Footer


const Navbar = () => {

  return (
    <nav className="bg-white dark:bg-gray-900 fixed w-full z-20 top-0 start-0 border-b border-gray-200 dark:border-gray-600">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-evenly mx-auto p-4">
        <a href="https://strangesolutions.ai/" className="flex items-center space-x-3 rtl:space-x-reverse">
            <span className="text-white text-3xl ">StrangeSolutions</span>
        </a>
      </div>
    </nav>

)}

export default Navbar